import { useState, useEffect } from "react";
import ApiServices from "../../ApiService/ApiConfig";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { FormGroup, Label, Input } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";

const CustomerReportLandingReportPage = (props) => {
  const [reportInfo, setReportInfo] = useState([]);
  const [reportListData, setListReportData] = useState([]);
  const [reportStatus, setReportStatus] = useState("Accepted");
  const [tableData, setTableData] = useState([]);
  const [withoutMutualFundData, setWithoutMutualFundData] = useState([]);
  const [mutualFundData, setMutualFundData] = useState([]);
  const [remittanceAmt, setRemittanceAmt] = useState();
  const [tradeAdviceCode, setTradeAdviceCode] = useState();

  let {
    name: portfolio_name,
    resettoken: resetToken,
    resetvalidity,
    id,
    trade_advice_code,
  } = useParams();

  trade_advice_code = trade_advice_code.replace(/\+/g, " ");
  resetvalidity = resetvalidity.replace(/_/g, " ");

  useEffect(() => {
    LandingPageData();
    listTradeData();
  }, []);

  const LandingPageData = () => {
    ApiServices.listDummyReportData({portfolio_name})
      .then((res) => {
        console.log("--", res);
        setListReportData(res);
        console.log("YOUR CHANGES", resetvalidity, trade_advice_code);
        res.map((element) => {
          if (element.new_reset_token === resetToken) {
            setReportInfo(element);
            let tabData = element.dummy_model_data.filter(
              (item) =>
                item["User_Action"] === "Yes" &&
                !item["Name_of_Securities"].toLowerCase().includes("cash")
            );
            let tabDataNew = tabData.map((data) => ({
              Name_of_Securities: data["Name_of_Securities"],
              Sub_Asset_Class: data["Sub_Asset_Class"],
              Suggested_Trade: toIndianCurrency(data["Suggested_Trade"]),
              Suggested_Amount_Allocation: toIndianCurrency(
                Math.ceil(data["Suggested_Amount_Allocation"] / 100) * 100
              ),
              Buy_Sell: data["Buy_Sell"],
              Price: data["Price"],
            }));
            let tabDataWithoutMutualFund = tabDataNew.filter(
              (data) =>
                !data["Sub_Asset_Class"].toLowerCase().includes("mutual fund")
            );
            let tabDataWithMutualFund = tabDataNew.filter((data) =>
              data["Sub_Asset_Class"].toLowerCase().includes("mutual fund")
            );
            setWithoutMutualFundData(tabDataWithoutMutualFund);
            setMutualFundData(tabDataWithMutualFund);
            let newWithMutualFundData = tabDataWithMutualFund.filter(
              (ele) => ele["Buy_Sell"] === "Buy"
            );
            let RemittanceAmt = 0;
            newWithMutualFundData.forEach((element) => {
              RemittanceAmt += Number(
                element["Suggested_Amount_Allocation"].replace(/,/g, "")
              );
            });
            // const RoundedRemittanceAmt =
            // 	Math.ceil(RemittanceAmt / 100) * 100;
            setRemittanceAmt(RemittanceAmt);
            console.log(
              tabDataWithoutMutualFund,
              "==",
              tabDataWithMutualFund,
              "--",
              RemittanceAmt
            );
            setTableData(tabData);
          }
        });
        console.log("120", reportInfo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const listTradeData = () => {
    ApiServices.listAllTradeMISReportData()
      .then((res) => {
        console.log("--", res);
        setListReportData(res);
        res.map((element) => {
          if (element.reportResetToken === resetToken) {
            setTradeAdviceCode(element.trade_advice_code);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toIndianCurrency = (num) => {
    if (num !== undefined) {
      const curr = Number(num).toLocaleString("en-IN", {
        currency: "INR",
      });
      return curr;
    }
  };

  const columns = [
    {
      headerName: "Script Name",
      field: "Name_of_Securities",
    },
    {
      headerName: "Sugested Trade Qty",
      field: "Suggested_Trade",
    },
    {
      headerName: "Buy/Sell",
      field: "Buy_Sell",
      cellStyle: (params) => {
        if (params.value === "Buy") return { color: "#FF9F1C" };
        if (params.value === "Sell") return { color: "green" };
      },
    },
    {
      headerName: "Price",
      field: "Price",
    },
  ];

  const MutualFundColumns = [
    {
      headerName: "Script Name",
      field: "Name_of_Securities",
    },
    {
      headerName: "Amount",
      field: "Suggested_Amount_Allocation",
    },
    {
      headerName: "Buy/Sell",
      field: "Buy_Sell",
      cellStyle: (params) => {
        if (params.value === "Buy") return { color: "#FF9F1C" };
        if (params.value === "Sell") return { color: "green" };
      },
    },
    // {
    //     headerName: 'Price', field:'Price',
    // },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  const SubmitHandler = async (e) => {
    // console.log(modalData);
    e.preventDefault();
    let reportModelData = [];
    reportInfo.dummy_model_data.map((element) => {
      let obj = {};
      obj["ISIN Number"] = element["ISIN_Number"];
      obj["Name of Securities"] = element["Name_of_Securities"];
      obj["Asset Class"] = element["Asset_Class"];
      obj["Sub Asset Class"] = element["Sub_Asset_Class"];
      obj["CMP"] = element["CMP"];
      obj["Ratio Start Pt"] = element["Ratio_Start_Pt"];
      obj["Ratio End Pt"] = element["Ratio_End_Pt"];
      obj["Prescribed Weight"] = element["Prescribed_Weight"];
      obj["User Action"] = element["User_Action"];
      obj["Suggested Trade"] = element["Suggested_Trade"];
      obj["Suggested Amount Allocation"] =
        element["Suggested_Amount_Allocation"];
      obj["Buy Sell"] = element["Buy_Sell"];
      obj["Price"] = element["Price"];
      reportModelData.push(obj);
    }, "");

    console.log("reportModelData", reportModelData);

    let dataNew;
    if (reportInfo.funds_added === null) {
      dataNew = {
        status: reportStatus,
        inception_val_added_status: 1,
        updated_inception_val: reportInfo.customer_portfolio_inception_value,
        model: reportModelData,
        user_broker_cust_id: id,
        resetToken: resetToken,
        source: reportInfo.source,
        trade_advice_code,
      };
    } else {
      dataNew = {
        status: reportStatus,
        funds_added: reportInfo.funds_added,
        model: reportModelData,
        user_broker_cust_id: id,
        resetToken: resetToken,
        source: reportInfo.source,
        trade_advice_code,
      };
    }

    const destUrl = `${process.env.REACT_APP_URL_API}/customerportfolio/updatefundsgloballybroker/${portfolio_name}`;
    const data = await axios({ method: "post", url: destUrl, data: dataNew })
      .then((res) => {
        toast.success(`Response Recorded Successfully`);
        props.history.push("/thank");
      })
      .catch((err) => {
        if (err.response.status === 406) toast.warning("Response already sent");
      });
  };

  return (
    <div className="container">
      <div className="container-fluid text-center">
        <h3
          className="pb-3 pt-3"
          style={{ backgroundColor: "#eff6f9", marginTop: 100 }}
        >
          Transaction Report
        </h3>
      </div>

      <div
        className="ag-theme-alpine"
        style={{
          height: 400,
          width: 550,
          marginTop: 30,
          marginLeft: "27%",
        }}
      >
        <div style={{ border: "1px solid #babfc7", padding: "15px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <p>
                  <b>Investor's Name</b>{" "}
                  <span style={{ paddingLeft: "5px" }}>
                    : {reportInfo.client_name}
                  </span>
                </p>
                <p>
                  <b>Trade ID</b>{" "}
                  <span style={{ paddingLeft: "5px" }}>
                    : {tradeAdviceCode}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <AgGridReact
          rowData={withoutMutualFundData}
          columnDefs={columns}
          defaultColDef={defaultColDef}
        />
        <br />
        {mutualFundData.length ? (
          <AgGridReact
            rowData={mutualFundData}
            columnDefs={MutualFundColumns}
            defaultColDef={defaultColDef}
          />
        ) : null}
        <br />
        {mutualFundData.length ? (
          <p>
            Required remittance to MFU account - Rs{" "}
            {toIndianCurrency(Math.round(remittanceAmt).toString())}
          </p>
        ) : null}
        <form onSubmit={SubmitHandler}>
          {/* <div className="form-check form-group">
								<Field
									type="checkbox"
									name="acceptTerms"
									className={
										'form-check-input ' +
										(errors.acceptTerms &&
										touched.acceptTerms
											? ' is-invalid'
											: '')
									}
								/>
								<label
									htmlFor="acceptTerms"
									className="form-check-label"
								>
									I Accept.
								</label>
								<ErrorMessage
									name="acceptTerms"
									component="div"
									className="invalid-feedback"
								/>
							</div> */}
          <div className="form-group" style={{ height: "100px" }}>
            <button className="btn btn-success mb-3 mr-2 mt-3">Approve</button>
            {/* <button type="reset" className="btn btn-secondary">Reset</button> */}
          </div>
        </form>

        {/* <Formik
					initialValues={{
						acceptTerms: false,
					}}
					validationSchema={Yup.object().shape({
						acceptTerms: Yup.bool().oneOf(
							[true],
							'Accept Terms is required'
						),
					})}
					onSubmit={SubmitHandler}
				>
					{({ errors, status, touched }) => (
						<div
							className="container"
							style={{ paddingBottom: '25px' }}
						>
							<Form>
								<div className="form-check form-group">
									<Field
										type="checkbox"
										name="acceptTerms"
										className={
											'form-check-input ' +
											(errors.acceptTerms &&
											touched.acceptTerms
												? ' is-invalid'
												: '')
										}
									/>
									<label
										htmlFor="acceptTerms"
										className="form-check-label"
									>
										I Agree With The Change
									</label>
									<ErrorMessage
										name="acceptTerms"
										component="div"
										className="invalid-feedback"
									/>
								</div>
							</Form>
						</div>
					)}
				</Formik> */}
      </div>
    </div>
  );
};

export default CustomerReportLandingReportPage;
