import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API;
const AUTH_TOKEN = `bearer ${localStorage.getItem("token")}`;
axios.defaults.headers.common["Authorization"] = AUTH_TOKEN;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

const ApiServices = {
  // auth api's
  async login(inputData) {
    try {
      const response = await axios.post("signin", inputData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  //email check
  async checkEmail(inputData) {
    try {
      const response = await axios.post("checkEmail", inputData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async checkAPI() {
    try {
      const response = await axios.get("/health-check");
      console.log("response", response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  //update password
  async ResetPassword(token, inputData) {
    try {
      const response = await axios.post(
        `/user/resetpassword/${token}`,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  // user api's
  async addUser(inputData) {
    try {
      const response = await axios.post("/user/create", inputData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listUser() {
    try {
      const response = await axios.get("/user/list");
      console.log("response", response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listAllUser() {
    try {
      const response = await axios.get("/user/listAll");
      console.log("response", response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listUserWithStatus() {
    try {
      const response = await axios.get("/user/listUsersWithStatus");
      console.log("response", response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateUser(id, inputData) {
    try {
      const response = await axios.post(`/location/update/${id}`, inputData);
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // customer api's
  async addCustomer(inputData) {
    try {
      const response = await axios.post("/customer/create", inputData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listCustomer() {
    try {
      const response = await axios.get("/customer/list");
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listCustomerWithStatus() {
    try {
      const response = await axios.get("/customer/customerlistwithstatus");
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listBrokerWithStatus() {
    try {
      const response = await axios.get("broker/list");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateCustomer(id, inputData) {
    try {
      const response = await axios.post(`/customer/update/${id}`, inputData);
      console.log("WHAT YOU ARE LOOKING FOR.......", response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async sendOTP(id, inputData) {
    try {
      const response = await axios.post(`/customer/sendOTP/${id}`, inputData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateCustomerEmailMobileVerificationStatus(
    id,
    resetOTPToken,
    inputData
  ) {
    try {
      const response = await axios.post(
        `/customer/updatecustomeremailverificationstatus/${id}/${resetOTPToken}`,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateCustomerEmailMobileToken(id, inputData) {
    try {
      const response = await axios.post(
        `/customer/updateemailmobiletoken/${id}`,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateCustRiskProf(id, inputData) {
    try {
      const response = await axios.post(`/customer/riskscore/${id}`, inputData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async changePassword(id, inputData) {
    try {
      const response = await axios.post(
        `/location/changePassword/${id}`,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listGrievance() {
    try {
      const response = await axios.get("/grievance/list");
      console.log("response", response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async addGrievance(inputData) {
    try {
      const response = await axios.post("/grievance/create", inputData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateGrievance(id, inputData) {
    try {
      const response = await axios.post(`/grievance/update/${id}`, inputData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listCustomerProfile(id, inputData) {
    try {
      const response = await axios.get(`/customer/customerportfolio/${id}`);
    } catch (error) {
      throw error;
    }
  },
  async addModel(inputData) {
    try {
      const response = await axios.post("/model/create", inputData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listModel() {
    try {
      const response = await axios.get("/model/list");
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listStatusVerifiedModel() {
    try {
      const response = await axios.get("/model/modelstatusverifiedlist");
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getModel(model_name) {
    try {
      const response = await axios.get("/model/modellist/" + model_name);
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getModelCustomerPortfolio(model_name) {
    try {
      const response = await axios.get("/model/modellist/" + model_name);
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getReportData(model_name) {
    try {
      const response = await axios.get(
        "/model/modellist/getreportdata/" + model_name
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async verifyReportData(model_name) {
    try {
      const response = await axios.get(
        "/model/modellist/verifymodelreport/" + model_name
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async verifyModelReportData(model_name) {
    try {
      const response = await axios.get(
        "/model/modellist/verifymodeldatareport/" + model_name
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async verifyReportDataCustomerSecuritoes(model_name) {
    try {
      const response = await axios.get(
        "/model/modellist/verifyModelReportDataCustomerSecirities/" + model_name
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async generateReport(model_name, inputData) {
    try {
      const response = await axios.post(
        "/model/modellist/generatereport/" + model_name,
        inputData
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async deleteModel(model_name) {
    try {
      const response = await axios.delete("/model/delete/" + model_name);
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateModel(inputData, model_name) {
    try {
      const response = await axios.post(
        "/model/update/" + model_name,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async customerPortfolioList() {
    try {
      const response = await axios.get("/customerportfolio/list");
      console.log("response", response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async createCustomerPortfolio(inputData) {
    try {
      const response = await axios.post("/customerportfolio/create", inputData);
      console.log("response", response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateModelCustomerPortfolio(inputData) {
    try {
      const response = await axios.post(
        "/customerportfolio/updatemodelportfolio",
        inputData
      );
      console.log("response", response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getCustomerPortfolio(portfolio_name) {
    try {
      const response = await axios.get(
        "/customerportfolio/customerportfoliolist/" + portfolio_name
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateCustomerPortfolio(inputData, portfolio_name) {
    try {
      const response = await axios.post(
        "/customerportfolio/update/" + portfolio_name,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updatePortfolioFromManualUpdate(inputData) {
    try {
      const response = await axios.post(
        "/customerportfolio/updatePortfolioFromManualUpdate",
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async deleteCustomerPortfolio(portfolio_name) {
    try {
      const response = await axios.delete(
        "/customerportfolio/delete/" + portfolio_name
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateFundsGlobally(inputData, portfolio_name) {
    try {
      const response = await axios.post(
        "/customerportfolio/updatefundsglobally/" + portfolio_name,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateModelStatus(inputData, model_name) {
    try {
      const response = await axios.post(
        "/model/updateRecord/" + model_name,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getPortfolioCustomerReportData(portfolio_name) {
    try {
      const response = await axios.get(
        "/customerportfolio/customerportfoliolist/" + portfolio_name
      );
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateUserAction(id, inputData) {
    try {
      const response = await axios.post(
        "/model/updateUserAction/" + id,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async deleteCustomer(inputData) {
    try {
      const response = await axios.delete("/customer/delete", {
        data: inputData,
      });
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateCustomerStatus(id, inputData) {
    try {
      const response = await axios.post(
        "/customer/updatecustomerstatus/" + id,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async deleteUser(inputData) {
    try {
      const response = await axios.delete("/user/delete", {
        data: inputData,
      });
      console.log(response);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async deleteBroker(data) {
    console.log("PASSEED", data);
    try {
      const response = await axios.delete("/broker/delete", {
        data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateBrokerStatus(id, inputData) {
    try {
      const response = await axios.patch(
        "/broker/status-update/" + id,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateUserStatus(id, inputData) {
    try {
      const response = await axios.post(
        "/user/updateuserstatus/" + id,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateThreshold(id, inputData) {
    try {
      const response = await axios.post(
        "/model/updatethreshold/" + id,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listDummyReportData({portfolio_name=null}={}) {
    try {
      const response = await axios.get(`/reportdummydata/list`, {
        params: portfolio_name ? { portfolio_name } : {},
      })
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listTradeMISReportData() {
    try {
      const response = await axios.get(`/trademisreport/list`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listAllTradeMISReportData() {
    try {
      const response = await axios.get(`/trademisreport/listAll`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listAllTradeMISSAData() {
    try {
      const response = await axios.get(`/trademisreport/listAllSAData`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listAllTradeMISCustomerData() {
    try {
      const response = await axios.get(`/trademisreport/listAllCustomerData`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listAllTradeMISBrokerData() {
    try {
      const response = await axios.get(`/trademisreport/listAllBrokerData`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async listAllTradeMISReportsData() {
    try {
      const response = await axios.get(`/trademisreport/listAllReportsData`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async statusWiseCount(inputData) {
    try {
      const response = await axios.get(`/trademisreport/count`, inputData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async MailToBrokerAddFunds(id, inputData) {
    try {
      const response = await axios.post(
        "/customerportfolio/sendMailToBrokerAddFunds/" + id,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  bulkMailsToBrokers(data) {
    return axios.post("/customerportfolio/bulkMailsToBrokers/", data);
  },

  async ResendLinkForSAAddFunds(id, inputData) {
    try {
      const response = await axios.post(
        "/customerportfolio/resendlinkforupdatefundsglobally/" + id,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async ResendLinkForSAModPort(id, inputData) {
    try {
      const response = await axios.post(
        "/customerportfolio/resendlinksa/" + id,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async ResendLinkForCustomerAddFunds(id, inputData) {
    try {
      const response = await axios.post(
        "/customerportfolio/resendlinkforupdatefundsgloballycustomer/" + id,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async ResendLinkForSADVWM(id, inputData) {
    try {
      const response = await axios.post(
        "/customerportfolio/resendlinkforSADVWM/" + id,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async ResendLinkForCustomerDVWM(id, inputData) {
    try {
      const response = await axios.post(
        "/customerportfolio/resendlinkforCustomerDVWM/" + id,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async ResendLinkForCustomerModPort(id, inputData) {
    try {
      const response = await axios.post(
        "/customerportfolio/resendlinkcustomer/" + id,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async ResendLinkForBrokerAddFunds(id) {
    try {
      const response = await axios.post(
        "/customerportfolio/resendlinkforupdatefundsgloballybroker/" + id
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async ResendLinkForBrokerDVWM(id) {
    try {
      const response = await axios.post(
        "/customerportfolio/resendlinkforBrokerDVWM/" + id
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async ResendLinkForBrokerModPort(id) {
    try {
      const response = await axios.post(
        "/customerportfolio/resendlinkbroker/" + id
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async MailToBrokerModPort(id, inputData) {
    console.log(inputData, "MAIL TO BROKER, MODPORT");
    try {
      const response = await axios.post(
        "/customerportfolio/sendMailToBrokerModPort/" + id,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async filterReports(inputData) {
    try {
      const response = await axios.post(
        `/trademisreport/filterlist`,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getRecordByUniqueId(id) {
    try {
      const response = await axios.get(
        "/reportdummydata/getRecordByUniqueId/" + id
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getMutualFundReport() {
    try {
      const response = await axios.get("/mutual-fund-report/listmutualfunds");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async updateUniqueCodeMutualFund(unique_code, inputData) {
    try {
      const response = await axios.post(
        "/mutual-fund-report/update-unique-code/" + unique_code,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async GetServiceMails(id) {
    try {
      const response = await axios.get("/getServiceMails", {
        params: { id },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async UpdateServiceMails(patchData, id) {
    try {
      const response = await axios({
        url: "/updateServiceMails",
        method: "PATCH",
        params: { id },
        data: patchData,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async GetSubAssetList() {
    try {
      const response = await axios.get("/getSubAssetList");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async GetCompanyAddress() {
    try {
      const response = await axios.get("/get-address");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async GetBrokersList() {
    try {
      const response = await axios.get("/broker/active/list");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async AddSubAssetToList(data) {
    try {
      const response = await axios.post("/addSubAssetToList", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async AddCompanyAddress(data) {
    try {
      const response = await axios.post("/add-address", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async UpdateCompanyAddress(data) {
    try {
      const response = await axios.patch("/update-address", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async DeleteSubAssetFromList(id) {
    try {
      const response = await axios.delete("/deleteSubAssetFromList", {
        params: { id },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async GetMailFormats() {
    try {
      const response = await axios.get("/getAllMailFormats");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async UpdateMailFormat(data) {
    try {
      const response = await axios.patch("/updateMailFormat", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async SendMailCustomerDVWM(name, data) {
    try {
      const response = await axios.post(
        "/model/sendMailCustomerDVWM/" + name,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async MailToBrokerDVWM(id, inputData) {
    try {
      const response = await axios.post(
        "/customerportfolio/sendMailToBrokerDVWM/" + id,
        inputData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async OTPInWhapsapp(inputData) {
    try {
      const response = await axios.post("/customer/OPTInWhatsapp", inputData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // broker api's
  async addBroker(inputData) {
    try {
      const response = await axios.post("/broker/create", inputData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getAllBrokers() {
    try {
      const response = await axios.get("broker/active/list");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getBrokersByID(id) {
    try {
      const response = await axios.get(`broker/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async updateBrokerbyID(id, inputData) {
    console.log("id and inputData>>", id, inputData);
    try {
      const response = await axios.patch(`broker/update/${id}`, inputData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ApiServices;
